<template>
  <sm-master-layout background-color="#f5f5f5">
    <div id="container">
      <br>
      <span class="sm-address__headline">
          Bitte Lieferadresse und Rechnungsadresse auswählen.
      </span>

      <h6> LIEFERADRESSE: </h6>
      <ion-list class="sm-address__list" lines="full" mode="ios">
        <ion-item-group v-for="(address, index) in deliveryAddresses" :key="index">
          <ion-radio-group :value="getDeliveryAddress ? getDeliveryAddress.id : deliveryAddresses[0].id" mode="md">
              <ion-item class="sm-address__item" @click="onToggleDelivery(address)">
                  <ion-grid>
                    <ion-row v-if="getCustomer && getCustomer.defaultShippingAddressId === address.id">
                      <ion-col class="sm-address__item__default">
                        STANDARD-ADRESSE
                      </ion-col>
                    </ion-row>
                    <ion-row v-if="address.company">
                      <ion-col>
                        <ion-label class="sm-address__item__company">{{address.company}}</ion-label>
                      </ion-col>
                    </ion-row>
                    <ion-row v-if="address.street">
                      <ion-col>
                        {{address.street}}
                      </ion-col>
                    </ion-row>
                    <ion-row v-if="address.city || address.zipcode">
                      <ion-col>
                        {{address.city}} {{address.zipcode}}
                      </ion-col>
                    </ion-row>
                    <ion-row v-if="address.firstName || address.lastName">
                      <ion-col>
                        {{address.firstName}} {{address.lastName}}
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                  <ion-radio slot="start" :value="address.id"></ion-radio>
              </ion-item>
          </ion-radio-group>
        </ion-item-group>
      </ion-list>

      <h6> RECHUNUNGSADRESSE: </h6>
      <ion-list class="sm-address__list" lines="full" mode="ios">
        <ion-item-group v-for="(address, index) in invoiceAddresses" :key="index">
          <ion-radio-group :value="getInvoiceAddress ? getInvoiceAddress.id : invoiceAddresses[0].id" mode="md">
            <ion-item class="sm-address__item" @click="onToggleInvoice(address)">
              <ion-grid>
                <ion-row v-if="getCustomer && getCustomer.defaultBillingAddressId === address.id">
                  <ion-col class="sm-address__item__default">
                    STANDARD-ADRESSE
                  </ion-col>
                </ion-row>
                <ion-row v-if="address.company">
                  <ion-col>
                    <ion-label class="sm-address__item__company">{{address.company}}</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row v-if="address.street">
                  <ion-col>
                    {{address.street}}
                  </ion-col>
                </ion-row>
                <ion-row v-if="address.city || address.zipcode">
                  <ion-col>
                    {{address.city}} {{address.zipcode}}
                  </ion-col>
                </ion-row>
                <ion-row v-if="address.firstName || address.lastName">
                  <ion-col>
                    {{address.firstName}} {{address.lastName}}
                  </ion-col>
                </ion-row>
              </ion-grid>
              <ion-radio slot="start" :value="address.id"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-item-group>
      </ion-list>

      <div class="sm-list-button">
        <div class="sm-button-next">
          <ion-button :disabled="lineItems.length <= 0" expand="block" color="primary" @click="() => router.push({name: 'shipping'})">WEITER</ion-button>
        </div>

        <div class="sm-button-back">
          <ion-button expand="block" color="secondary" @click="() => router.back()">ZURÜCK</ion-button>
        </div>
      </div>
    </div>
  </sm-master-layout>
</template>

<script lang="javascript">
import { defineComponent } from 'vue';
import {
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonCol,
  IonRow,
  IonGrid,
  IonButton,
  alertController
} from '@ionic/vue';
import ScreenLoadingMixin from '@/mixins/screen-loading.mixin';
import CommonMixin from '@/mixins/common.mixin';
import SmMasterLayout from "@/components/layout/SmMasterLayout";
import ApiService from '@/services/api.service';
import CartService from '@/services/cart.service';
import { CUSTOMER_ADDRESSES_API_PATH, CHECKOUT_CART_API_PATH, CHECKOUT_CART_LINE_ITEMS_API_PATH } from "../../constants/api.constant";
import { mapGetters, mapMutations } from "vuex";
import { useRouter } from 'vue-router';
import { alertCircle } from "ionicons/icons";

export default defineComponent({
  name: 'Addresses',

  mixins: [ScreenLoadingMixin, CommonMixin],

  components: {
    SmMasterLayout,
    IonItem,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonCol,
    IonRow,
    IonGrid,
    IonButton
  },

  ionViewWillEnter() {
    this.setHeaderTitle('Kasse / Versandadresse');
    this.loadAddressesFromApi();
    this.fetchCart();
    this.setNumberStep('0');
  },

  data() {
    return {
      deliveryAddresses: [],
      invoiceAddresses: [],
      lineItems: []
    }
  },

  setup() {
    const router = useRouter();
    return { router, alertCircle };
  },

  active() {
    this.loadAddressesFromApi();
    this.fetchCart();
  },

  computed: {
    ...mapGetters('address', ['getDeliveryAddress', 'getInvoiceAddress']),
    ...mapGetters('auth', ['getCustomer']),
  },

  methods: {
    ...mapMutations('common', ['setHeaderTitle', 'setNumberStep']),
    ...mapMutations('address', ['setDeliveryAddress', 'setInvoiceAddress']),

    /* eslint-disable */
    async noLineItemsInfo(header, message, doOrderAgain) {
      return new Promise(async (resolve) => {
        const confirm = await alertController.create({
          header,
          message,
          buttons: [
            {
              text: 'Ok',
              role: 'cancel',
              handler: () => {
                return resolve(false);
              },
            }
          ]
        });

        await confirm.present();
      });
    },
    /* eslint-disable */

    async fetchCart () {
      const loading = await this.presentScreenLoading();

      try {
        const deleteCartResponse = await ApiService.delete(CHECKOUT_CART_API_PATH);
        const createCartResponse = await ApiService.get(CHECKOUT_CART_API_PATH);

        if (deleteCartResponse.status === 204 && createCartResponse.status === 200) {
          const lineItemsFromIdb = await CartService.getLineItemsFromIdb(this.getCustomer.id);

          if (lineItemsFromIdb.length > 0) {
            const pushItemsToCartResponse = await ApiService.post(CHECKOUT_CART_LINE_ITEMS_API_PATH, {
              items: lineItemsFromIdb
            });

            if (pushItemsToCartResponse.status === 200) {
              this.lineItems = lineItemsFromIdb;
            }
          } else {
            this.dismissScreenLoading(loading);
            const noLineItemsInfoResponse = await this.noLineItemsInfo('Ihr Warenkorb ist leer', 'Bitte scannen Sie Artikel und legen diese in den Warkenkorb.');
          }
        }

        this.dismissScreenLoading(loading);
      } catch (e) {
        console.log(e);
        this.dismissScreenLoading(loading);
        await this.noLineItemsInfo('Ihr Warenkorb ist leer', 'Bitte scannen Sie Artikel und legen diese in den Warkenkorb.');
      }
    },

    async loadAddressesFromApi() {
      const loading = await this.presentScreenLoading();

      try {
        const response = await ApiService.post(CUSTOMER_ADDRESSES_API_PATH);
        if (!response.data.elements || response.data.elements.length <= 0) {
          this.deliveryAddresses = [this.getCustomer.defaultShippingAddress];
          this.invoiceAddresses = [this.getCustomer.defaultBillingAddress];

          this.setDeliveryAddress(this.deliveryAddresses);
          this.setInvoiceAddress(this.invoiceAddresses);
          this.dismissScreenLoading(loading);
          return;
        }
        
        const deliveryAddressesSorted = response.data.elements.sort((address) => {
          if (address.id === this.getCustomer.defaultShippingAddressId) {
            return -1
          } else {
            return 0
          }
        });

        this.deliveryAddresses = deliveryAddressesSorted;
        this.setDeliveryAddress(this.getDeliveryAddress || deliveryAddressesSorted[0]);

        const invoiceAddressesSorted = response.data.elements.sort((address) => {
          if (address.id === this.getCustomer.defaultBillingAddressId) {
            return -1
          } else {
            return 0
          }
        });

        this.invoiceAddresses = invoiceAddressesSorted;
        this.setInvoiceAddress(this.getInvoiceAddress || invoiceAddressesSorted[0]);

        this.dismissScreenLoading(loading);
      } catch (e) {
        console.log(e);
        this.dismissScreenLoading(loading);
      }
    },

    onToggleDelivery(address) {
      this.setDeliveryAddress(address);
    },

    onToggleInvoice(address) {
      this.setInvoiceAddress(address);
    }
  }
})
</script>

<style scoped>
  #container {
    margin-bottom: 10rem;
  }

  h6 {
    margin-top: 1.5rem;
  }

  .sm-address__list {
    --ion-background-color: var(--ion-color-light);
  }

  .sm-address__item {
    align-items: flex-start;
  }

  .sm-address__item__default {
    color: darkgrey;
    font-weight: 700
  }

  .sm-address__item__company {
    font-weight: 700
  }

  ion-grid {
    --ion-grid-padding: 1rem;
  }

  ion-radio {
    margin: 2rem 0.5rem 2rem 1rem;
  }
</style>
